import basePageFields from '@fragments/pages/basePageFields';

import StreamTieredFragment from '@fragments/shared/StreamTieredFragment';
import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';
import ProgramsSummaryFragment from '@fragments/summaries/ProgramsSummaryFragment';
import ContentDynamicSortableFragment from '@fragments/shared/ContentDynamicSortableFragment';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import SubnavFragment from '@fragments/shared/SubnavFragment';
import SeoFragment from '@fragments/shared/SeoFragment';

export default gql`
    fragment ProgramFragment on Program {
        ${basePageFields}
        ...ProgramsSummaryFragment
        latestWork {
            ...ContentDynamicSortableFragment
        }
        contributors {
            ...PersonSummaryFragment
        }
        subNav {
            ...SubnavFragment
        }
        heroProgramsProjects {
            heroImage {
                ...ImageSummaryFragment
            }
            mobileHeroImage {
                ...ImageSummaryFragment
            }
            logo {
                ...ImageSummaryFragment
            }
        }
        dek
        about
        featuredContent {
            ...ContentDynamicSortableFragment
        }
        displayExperts
        streamTiered {
            ...StreamTieredFragment
        }
        socialNetworks {
            handle
            id
            network
        }
        projects {
            value {
                ... on Project {
                    id
                    title
                    path
                    dek
                    contributors {
                        id
                        avatar {
                            url
                            width
                            height
                            altText
                        }
                    }
                }
                ... on Collection {
                    id
                    title
                    path
                    dek
                    contributors {
                        id
                        avatar {
                            url
                            width
                            height
                            altText
                        }
                    }
                }
            }
        }
        seo {
            ...SeoFragment
        }
    }
    ${SubnavFragment}
    ${ImageSummaryFragment}
    ${StreamTieredFragment}
    ${PersonSummaryFragment}
    ${ProgramsSummaryFragment}
    ${ContentDynamicSortableFragment}
    ${SeoFragment}
`;
